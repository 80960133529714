import React from 'react';
import Layout from '../layouts/main/Layout';
import Page404 from '../components/Page404/Page404';
import NoIndex from '../util/NoIndex/NoIndex';

const NotFoundPage = ({ ...props }) => {
  const { originalPath } = props.pathContext.intl;
  return (
    <Layout originalPath={originalPath}>
      <NoIndex />
      <Page404 />
    </Layout>
  );
};

export default NotFoundPage;

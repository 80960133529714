import React from 'react';
import { Link } from 'gatsby';
import Image from '../../util/Image';
import data from '../../assets/images/not-found/not-found.json';


const Page404 = ({ locale }) => {
  const { notFoundImageSrc, notFoundImageAlt } = data.notFound;
  const link = locale === 'pl' ? `/${locale}` : '';
  return (
    <section className="section-404">
      <Image filename={notFoundImageSrc} alt={notFoundImageAlt} className="not-found-img" />
      <h2>Ooops!</h2>
      <h3>It seems like this page does not exist! :(</h3>
      <p>Feel free to look around.</p>
      <Link to={`${link}/`} aria-label="Home" className="btn btn-link">Home</Link>
    </section>
  );
};
export default Page404;
